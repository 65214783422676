import { Bodies } from 'matter-js';
import { Graphics, Sprite } from 'pixi.js';

import Entity, { EntityOptions } from './entity';

export default class Spawner extends Entity {
  constructor(opts: EntityOptions) {
    super({ ...opts, width: 50, height: 10 });
    this.init();
    this.shouldUpdate = false;
    this.update();
  }

  public init = () => {
    const palette = [0x4ba3c3, 0x175676, 0xba324f, 0xd62839];
    this.color = palette[Math.floor(Math.random() * palette.length)];

    const body = Bodies.rectangle(this.x, this.y, this.width, this.height, { label: 'block', isStatic: true, isSensor: true, ...this.options });
    this.body = body;

    const shape = new Graphics();
    shape.beginFill(this.color);
    // shape.lineStyle(1, 0xffffff);
    shape.drawRect(0, 0, this.width, this.height);

    this.sprite = new Sprite((this.game.render.renderer as any).generateTexture(shape));
    this.sprite.anchor.x = 0.5;
    this.sprite.anchor.y = 0.5;
  };
}
