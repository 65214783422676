import * as firebase from 'firebase';
import { Bodies, Body, Query, Vector } from 'matter-js';
import { Graphics, Sprite, Text } from 'pixi.js';
import Keyboard from 'pixi.js-keyboard';

import Checkpoint from './checkpoint';
import Entity, { EntityOptions } from './entity';

// import Keyboard from "pixi.js-keyboard";
// import { direction } from "../../constants";

export default class Player extends Entity {
  public speed: number = 0.2;

  public jumping: boolean = false;
  public charging: boolean = false;
  public chargingSince: number = 0;

  public jumps: number = 1;
  public canJump: boolean = true;

  // public jumpMultiplier: number = 1;

  constructor(opts: EntityOptions) {
    super(opts);
    this.init();
  }

  public update() {
    // if player is colliding with ground, lower speed
    const collisions = (Query as any).collides(
      this.body,
      // this.game.engine.world.bodies.filter(body => body.label === 'platform'),
      this.game.engine.world.bodies.filter(body => body.label !== 'player' && body.collisionFilter.group === 0),
    );
    // console.log(collisions);

    // debugger;

    // collisions[]
    collisions.forEach(collision => {
      // debugger;
      const { bodyA, bodyB } = collision;
      if (bodyB === this.body || bodyA === this.body) {
        const floor = bodyB === this.body ? bodyA : bodyB;

        if (!floor.isSensor) {
          const absoluteNormalizedAngle = Math.abs(Math.atan2(Math.sin(floor.angle), Math.cos(floor.angle)));
          const offset = Math.PI / 25;
          if (
            (absoluteNormalizedAngle >= 0 && absoluteNormalizedAngle <= offset) ||
            (absoluteNormalizedAngle >= Math.PI - offset && absoluteNormalizedAngle <= Math.PI) ||
            (absoluteNormalizedAngle >= Math.PI - offset && absoluteNormalizedAngle <= Math.PI + offset) ||
            (absoluteNormalizedAngle >= 2 * Math.PI - offset && absoluteNormalizedAngle <= 2 * Math.PI)
          ) {
            if (!(this.game.keys[37] || this.game.keys[38] || this.game.keys[39] || this.game.keys[40])) {
              const x = this.body.velocity.x;
              const slowDownScale = 0.9;
              const finalX = Math.abs(x * slowDownScale) < 0.01 ? 0 : x * slowDownScale;

              // slowing down when touching slopes
              Body.setVelocity(this.body, { x: finalX, y: this.body.velocity.y });
            }
          }
        }
      }

      if ((bodyB === this.body || bodyA === this.body) && !bodyB.isSensor && !bodyA.isSensor) {
        if (collision.supports.length > 0) {
          let normalPosX = collision.supports[0].x;
          let normalPosY = collision.supports[0].y;

          if (collision.supports.length === 2) {
            normalPosX = (collision.supports[0].x + collision.supports[1].x) / 2;
            normalPosY = (collision.supports[0].y + collision.supports[1].y) / 2;
          }

          const angle = Math.atan2(normalPosY - this.body.position.y, normalPosX - this.body.position.x);
          const normalizedAngle = Math.atan2(Math.sin(angle), Math.cos(angle));

          if (normalizedAngle > -Math.PI / 8 || normalizedAngle < -Math.PI + Math.PI / 8) {
            if (this.jumps <= 0) this.jumps = 1;
            this.canJump = true;
          }
        }
      }
    });

    // get all collisions that are not checked
    const checkpoints = collisions
      .filter(collision => collision.bodyA.label === 'Checkpoint' || collision.bodyB.label === 'Checkpoint')
      .map(collision => (collision.bodyA.label === 'Checkpoint' ? collision.bodyA.entity : collision.bodyB.entity))
      .filter(checkpoint => !checkpoint.checked);

    checkpoints.forEach(checkpoint => (checkpoint.checked = true));

    const checkpointsLeft = Object.values(this.game.entities).filter(entity => entity instanceof Checkpoint && entity.checked);
    if (checkpointsLeft.length) {
      console.log('game end');

      // if (this.game.engine.timing.timestamp - pair.timeCreated > 100 && !this.levelFinished) {
      //     this.levelFinished = true;

      //     if (process.env.NODE_ENV === 'production') {
      //       notify({
      //         key: 'bbd579c0d69fca29e4b7c69acbdc7c435c0d007ad8121119a6ab1d25aced14c2',
      //         title: `Reutemeteut: Level ${this.manager.levelIndex} finished`,
      //         description: JSON.stringify({ timestamp: this.game.engine.timing.timestamp / 1000, username: this.manager.profile.username, uuid: this.manager.profile.uuid }),
      //       });

      //       if (this.manager.levelIndex === this.manager.levels.length - 1) {
      //         notify({
      //           key: '913b6cddf2f94d5a775c81a49c3932e1d0bc44917d91d94bd9db11b9df1a0f59',
      //           title: `Reutemeteut finisher: ${this.manager.profile.username}`,
      //           description: JSON.stringify({ username: this.manager.profile.username, uuid: this.manager.profile.uuid }),
      //         });
      //       }
      //     }
      //     this.manager.finish();
    }

    if (Keyboard.isKeyReleased('ArrowUp')) {
      this.canJump = true;
    }

    // console.log(collisions);

    // debugger;
    //&& (this as any).canJump && !(this as any).canJumpCooldown
    // && this.game.released[38] && Date.now() - this.game.released[38] > 10 && (this as any).jumps > 0
    // console.log(Keyboard.isKeyDown('ArrowUp'), this.canJump, this.jumps > 0);
    if (Keyboard.isKeyDown('ArrowUp') && this.canJump && this.jumps > 0) {
      // console.log('jump');
      const angles = [];
      // if touching multiple platforms, jump in that direciton
      collisions.forEach(collision => {
        const { bodyA, bodyB } = collision;
        const other = bodyB === this.body ? bodyA : bodyB;
        // debugger;
        // && other.activeContacts.length > 0
        if (!other.isSensor) {
          // const normalPosX = other.position.x + penetration.x;
          // const normalPosY = other.position.y + penetration.y;
          let normalPosX = collision.supports[0].x;
          let normalPosY = collision.supports[0].y;

          if (collision.supports.length === 2) {
            normalPosX = (collision.supports[0].x + collision.supports[1].x) / 2;
            normalPosY = (collision.supports[0].y + collision.supports[1].y) / 2;
          }

          const angle = Math.atan2(normalPosY - this.body.position.y, normalPosX - this.body.position.x);
          // console.log(this.body.position, { x: normalPosX, y: normalPosY }, angle);
          const normalizedAngle = Math.atan2(Math.sin(angle), Math.cos(angle));

          // console.log(angle);

          angles.push(normalizedAngle);
        }
      });

      // NOTE THATT THE Y AXIS IS INVERTED
      const averageAngle = angles.reduce((p, c) => p + c, 0) / angles.length;
      const invertedAngle = Math.atan2(Math.sin(averageAngle + Math.PI), Math.cos(averageAngle + Math.PI));

      let jumpDir = 0;

      let jump = Vector.create(0, 0);

      // https://github.com/liabru/matter-js/issues/767
      // calculating jump height
      // const { delta, correction: deltaCorrection } = this.game.get_delta_correction();

      const deltaSquared = Math.pow((this.game.delta * this.game.engine.timing.timeScale * this.body.timeScale) / this.game.correction, 2);
      // 1000 = 1 sec
      const correction = deltaSquared / 1000;
      // const correction = (((1000 * this.game.delta) / 1000) * this.game.delta) / 1000;

      const jumpAccelerator = 150;

      if (averageAngle > 0) {
        // standing on something

        jumpDir = [invertedAngle + Math.PI, -Math.PI / 2 + Math.PI].reduce((p, c) => p + c, 0) / 2 - Math.PI / 2;
        jump = Vector.create(this.body.velocity.x, -Math.sqrt(2 * this.game.engine.world.gravity.y * jumpAccelerator * correction));
      } else if (averageAngle > 0 - Math.PI / 8) {
        // slightly overhang from top right
        const newAverage = [invertedAngle, -Math.PI / 2].reduce((p, c) => p + c, 0) / 2;
        const newInverted = Math.atan2(Math.sin(newAverage + Math.PI), Math.cos(newAverage + Math.PI));

        jumpDir = newInverted + Math.PI / 2;
        jump = Vector.create(0, -Math.sqrt(2 * this.game.engine.world.gravity.y * jumpAccelerator * correction));
      } else if (averageAngle < -Math.PI + Math.PI / 8) {
        // slightly overhang from top left
        jumpDir = [invertedAngle + Math.PI, -Math.PI / 2 + Math.PI].reduce((p, c) => p + c, 0) / 2 - Math.PI / 2;
        jump = Vector.create(0, -Math.sqrt(2 * this.game.engine.world.gravity.y * jumpAccelerator * correction));
      } else {
        if (Number.isNaN(averageAngle)) {
          // in air jump

          jump = Vector.create(this.body.velocity.x, -Math.sqrt(2 * this.game.engine.world.gravity.y * jumpAccelerator * correction));
        }
      }

      Body.setVelocity(this.body, Vector.rotate(jump, jumpDir));

      this.game.released[38] = null;
      (this as any).jumps--;
      this.canJump = false;
      (this as any).canJumpCooldown = true;

      // setTimeout(() => {
      //   (this as any).canJumpCooldown = false;
      // }, 200);
      // // }

      const velocity = { ...this.body.velocity };

      Body.setVelocity(this.body, velocity);
      // debugger;
    }

    if (this.body.position.y > 3000) {
      this.game.manager.loadLevel();
    }

    const force = 0.5;

    if (Keyboard.isKeyDown('ArrowDown')) {
      Body.applyForce(this.body, this.body.position, {
        x: 0,
        y: force,
      });
    }

    if (Keyboard.isKeyDown('ArrowLeft')) {
      Body.applyForce(this.body, this.body.position, {
        x: -force,
        y: 0,
      });
    } else if (Keyboard.isKeyDown('ArrowRight')) {
      Body.applyForce(this.body, this.body.position, {
        x: force,
        y: 0,
      });
    }

    // locking speed to max 10
    const max = 10;
    // if (Math.abs(this.body.velocity.x) > Math.abs(this.body.velocity.y)) {
    if (Math.abs(this.body.velocity.x) > max) {
      const scale = this.body.velocity.x > 0 ? 1 : -1;
      this.body.velocity.x = max * scale;
    }
    // } else {
    if (Math.abs(this.body.velocity.y) > max) {
      const scale = this.body.velocity.y > 0 ? 1 : -1;
      this.body.velocity.y = max * scale;
    }

    if (this.game.manager.online && firebase.auth().currentUser) {
      if (
        parseFloat(this.previousPosition.x.toFixed(1)) !== parseFloat(this.body.position.x.toFixed(1)) ||
        parseFloat(this.previousPosition.y.toFixed(1)) !== parseFloat(this.body.position.y.toFixed(1))
      ) {
        this.game.manager.lobby.child(firebase.auth().currentUser.uid).update({
          body: {
            position: this.body.position && {
              x: parseFloat(this.body.position.x.toFixed(1)),
              y: parseFloat(this.body.position.y.toFixed(1)),
            },
          },
        });

        this.game.manager.lobby
          .child(firebase.auth().currentUser.uid)
          .onDisconnect()
          .remove();
      }
    }

    super.update();
  }

  // public moveLeft() {
  //   this.direction = direction.left;
  //   Body.applyForce(this.body, this.body.position, {
  //     x: -this.speed,
  //     y: 0
  //   });
  // }

  // public moveRight() {
  //   this.direction = direction.right;
  //   Body.applyForce(this.body, this.body.position, {
  //     x: this.speed,
  //     y: 0
  //   });
  // }

  public init = () => {
    // console.log('');
    const palette = [0x4ba3c3, 0x175676, 0xba324f, 0xd62839];
    this.color = palette[Math.floor(Math.random() * palette.length)];

    // this = Bodies.circle(x, y, 25, {
    //   label: 'player',
    //   // density: 1,
    //   friction: 0,
    //   // friction: 0.01,
    //   // frictionAir: 0.01,
    //   frictionAir: 0.0,

    //   frictionStatic: 0.0,
    //   // frictionStatic: 0.01,
    //   inertia: Infinity,
    //   render: {
    //     // strokeStyle: 'black',
    //     fillStyle: 'orange ',
    //   },
    // });

    // Body.setMass(this, 1000);

    // this.jumps = 0;
    const body = Bodies.circle(this.x, this.y, this.width, {
      label: 'player',
      friction: 0,
      collisionFilter: {
        group: -1,
      } as any,

      // friction: 1,
      // frictionAir: 0.01,
      frictionAir: 0.0,

      frictionStatic: 0,
      // frictionStatic: 0.01,
      inertia: Infinity,
      mass: 1000,
      // ...this.options,
    });
    this.body = body;
    // Body.setMass(this.body, 1000);

    const shape = new Graphics();
    shape.beginFill(this.color);
    // shape.lineStyle(1, 0xffffff);
    shape.drawCircle(0, 0, this.width);

    // debugger;

    const username = this.game.manager.profile.username;
    let text = new Text(username, { fontFamily: 'Arial', fontSize: 24, fill: 0x000 });
    text.x -= text.width / 2;
    text.y -= text.height * 2.2;

    this.sprite = new Sprite((this.game.render.renderer as any).generateTexture(shape));

    this.sprite.anchor.x = (this.body.render.sprite as any).xOffset;
    this.sprite.anchor.y = (this.body.render.sprite as any).yOffset;
    this.sprite.position.set(this.body.position.x, this.body.position.y);

    this.sprite.addChild(text);

    Body.rotate(this.body, this.angle);
    this.sprite.rotation = this.angle;
  };
}
