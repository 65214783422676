import { Bodies, Body } from 'matter-js';
import { Graphics, Sprite } from 'pixi.js';

import Entity, { EntityOptions } from './entity';

export default class Platform extends Entity {
  // public body: Body;\
  //compount of all bodies
  public moveOverX = 0;
  public moveOverY = 0;

  public initialX = 0;
  public initialY = 0;

  public angle = 0;

  public offset = Math.random() * 1000;

  constructor(opts: EntityOptions) {
    super(opts);
    this.moveOverX = opts.options && opts.options.moveOverX ? opts.options.moveOverX : 0;
    this.moveOverY = opts.options && opts.options.moveOverY ? opts.options.moveOverY : 0;
    this.angle = opts.options && opts.options.angle ? opts.options.angle : 0;

    this.init();
    this.shouldUpdate = false;
    this.update();
  }

  public init = () => {
    const palette = [0x4ba3c3, 0x175676, 0xba324f, 0xd62839];
    this.color = palette[Math.floor(Math.random() * palette.length)];
    const body = Bodies.rectangle(this.x, this.y, this.width, this.height, {
      isStatic: true,
      // isSensor: true,
      friction: 0,
      frictionAir: 0,
      frictionStatic: 0,
      label: 'platform',
      angle: this.angle,
      // ...this.options,
    });
    this.body = body;

    const shape = new Graphics();
    shape.beginFill(this.color);
    // shape.lineStyle(1, 0xffffff);
    shape.drawRect(this.x, this.y, this.width, this.height);
    this.sprite = new Sprite((this.game.render.renderer as any).generateTexture(shape));
    this.sprite.anchor.x = 0.5;
    this.sprite.anchor.y = 0.5;
  };

  public update() {
    // if (this.moveOverX !== 0 || this.moveOverY !== 0) {
    //   Body.setPosition(this.body, {
    //     x: this.initialX + Math.cos(this.game.engine.timing.timestamp / 1000 + this.offset) * this.moveOverX,
    //     y: this.initialY + Math.sin(this.game.engine.timing.timestamp / 1000 + this.offset) * this.moveOverY,
    //   });
    // }

    super.update();
    // Body.rotate(this.body, Math.PI / 50);
    // this.body.angle += Math.PI / 20;
  }
}
