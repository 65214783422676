import { Body } from 'matter-js';
import { Sprite } from 'pixi.js';
import { v4 } from 'uuid';

import Game from '../game';

export interface EntityOptions {
  id?: string;
  game: Game;
  x: number;
  y: number;
  width: number;
  height?: number;
  options?: any;
}

export default class Entity {
  public id: string;
  public body: Body;
  public sprite: Sprite;

  public game: Game;

  public x: number;
  public y: number;

  public previousPosition: {
    x: number;
    y: number;
  };

  public angle: number = 0;

  public width: number;
  public height: number;

  public color: number;

  public options: object;

  public shouldUpdate: boolean;

  /**
   *
   */
  constructor(opts: EntityOptions) {
    this.game = opts.game;
    this.x = opts.x;
    this.y = opts.y;
    this.width = opts.width;
    this.height = opts.height;
    this.options = opts.options;
    this.angle = opts.options && opts.options.angle ? opts.options.angle : 0;
    this.id = opts.id ? opts.id : v4();

    this.previousPosition = { x: this.x, y: this.y };

    this.shouldUpdate = true;
  }

  public update() {
    this.previousPosition = { ...this.body.position };
    const { x, y } = this.body.position;
    this.x = x;
    this.y = y;
    this.sprite.position.set(x, y);
    this.sprite.rotation = this.body.angle;
  }

  // i think pixi handles this for us
  // public render = () => {};
}
