import { Bodies, Body, World } from 'matter-js';
import { Graphics, Sprite } from 'pixi.js';

import Entity from './entity';

export default class Saw extends Entity {
  // public body: Body;\
  //compount of all bodie
  public radius;

  constructor(opts) {
    super(opts);
    this.radius = opts.options && opts.options.radius ? opts.options.radius : (opts.options.width * 2) / 4;

    // World.add(engine.world, this.body);
    this.init();
  }

  public init = () => {
    //
    // , Math.PI / 2
    const angles = [0, Math.PI / 2, Math.PI, Math.PI * 1.5];
    const bodies = [];

    this.sprite = new Sprite();

    angles.forEach(angle => {
      const body = Bodies.polygon(this.x, this.y, 3, this.width / 2, {
        isStatic: true,
        render: {
          fillStyle: 'grey',
        },
      });

      // body.render.sprite.debugger;

      const shape = new Graphics();
      shape.beginFill(0xff0000, 1);
      shape.drawPolygon(
        body.vertices.reduce((list, vector) => {
          list.push(vector.x, vector.y);
          return list;
        }, []),
      );

      shape.endFill();

      const sprite = new Sprite((this.game.render.renderer as any).generateTexture(shape));
      sprite.anchor.x = (body.render.sprite as any).xOffset;
      sprite.anchor.y = (body.render.sprite as any).yOffset;

      Body.rotate(body, angle);
      sprite.rotation = angle;

      // Body.rotate(body, angle);

      // sprite.position.set(sprite.x, sprite.y);
      // sprite.rotation = angle;

      bodies.push(body);
      this.sprite.addChild(sprite);
    });

    this.body = Body.create({
      parts: bodies,
      isStatic: true,
      label: 'Saw',
    });

    this.sprite.anchor.x = 0.5;
    this.sprite.anchor.y = 0.5;
  };

  public update() {
    Body.rotate(this.body, Math.PI / 50);
    super.update();
    // this.body.angle += Math.PI / 20;
  }
}
