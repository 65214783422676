import { Bodies, Body } from 'matter-js';
import { Graphics, Sprite } from 'pixi.js';

import Entity from './entity';

export default class Spike extends Entity {
  public angle;

  constructor(opts) {
    super(opts);
    this.width = opts.options && opts.options.length ? opts.options.length : opts.width;

    this.init();
    this.shouldUpdate = false;
    this.update();
  }

  public init = () => {
    const palette = [0x4ba3c3, 0x175676, 0xba324f, 0xd62839];
    this.color = palette[Math.floor(Math.random() * palette.length)];

    const body = Bodies.polygon(this.x, this.y, 3, this.width, {
      isStatic: true,
      // angle: (Math.random() * Math.PI * 2) / 5,

      label: 'Spike',
      render: {
        fillStyle: 'grey',
      },
      // }),
    });

    this.body = body;

    const shape = new Graphics();

    shape.beginFill(0xff0000, 1);
    // shape.lineStyle(0, 0xff0000, 1);
    // shape.moveTo(this.width * 2, 0);
    // shape.lineTo((this.width * 2) / 2, this.width * 2);
    // shape.lineTo(0, 0);
    // shape.lineTo((this.width * 2) / 2, 0);

    shape.drawPolygon(
      this.body.vertices.reduce((list, vector) => {
        list.push(vector.x, vector.y);
        return list;
      }, []),
    );
    shape.endFill();

    this.sprite = new Sprite((this.game.render.renderer as any).generateTexture(shape));
    this.sprite.anchor.x = (this.body.render.sprite as any).xOffset;
    this.sprite.anchor.y = (this.body.render.sprite as any).yOffset;
    this.sprite.position.set(this.body.position.x, this.body.position.y);

    Body.rotate(this.body, this.angle);
    this.sprite.rotation = this.angle;
  };
}
