import { Bodies, Body } from 'matter-js';
import { Graphics, Sprite, Text } from 'pixi.js';

import Entity, { EntityOptions } from './entity';

interface GhostOptions {
  username: string;
}

export default class Ghost extends Entity {
  public username: string;
  constructor(opts: EntityOptions & GhostOptions) {
    super(opts);
    this.username = opts.username;
    this.width = 25;
    this.height = 25;
    this.init();
  }

  public update() {
    super.update();
  }

  public init = () => {
    // console.log('');
    const palette = [0x4ba3c3, 0x175676, 0xba324f, 0xd62839];
    this.color = palette[Math.floor(Math.random() * palette.length)];

    const body = Bodies.circle(this.x, this.y, this.width, {
      label: 'ghost',
      friction: 0,
      isSensor: true,
      isStatic: true,
      collisionFilter: {
        group: -1,
      } as any,
      // collidesWidth: [0],
      // friction: 1,
      // frictionAir: 0.01,
      frictionAir: 0.0,

      frictionStatic: 0,
      // frictionStatic: 0.01,
      inertia: Infinity,
      mass: 1000,
      // ...this.options,
    });
    this.body = body;
    // Body.setMass(this.body, 1000);

    const shape = new Graphics();
    shape.beginFill(this.color);
    // shape.lineStyle(1, 0xffffff);
    shape.drawCircle(0, 0, this.width);

    // debugger;

    let text = new Text(this.username, { fontFamily: 'Arial', fontSize: 24, fill: 0x000 });
    text.x -= text.width / 2;
    text.y -= text.height * 2.2;

    this.sprite = new Sprite((this.game.render.renderer as any).generateTexture(shape));

    this.sprite.anchor.x = (this.body.render.sprite as any).xOffset;
    this.sprite.anchor.y = (this.body.render.sprite as any).yOffset;
    this.sprite.position.set(this.body.position.x, this.body.position.y);

    this.sprite.addChild(text);

    Body.rotate(this.body, this.angle);
    this.sprite.rotation = this.angle;
  };
}
