import Game from './game';

export default class Mouse {
  public position: {
    x: number;
    y: number;
  } = {
    x: 0,
    y: 0,
  };

  public game: Game;

  public down: boolean;

  public container;

  constructor(game, container?: HTMLElement) {
    this.game = game;

    // this.container = container ? container : window;
    this.container = window;

    this.container.addEventListener('mousedown', this.mouseDown);
    this.container.addEventListener('mouseUp', this.mouseUp);
    this.container.addEventListener('mousemove', this.mouseMove);
  }

  public mouseDown = e => {
    // console.log('DOWN', e);
  };
  public mouseUp = e => {
    // console.log('UP', e);
  };
  public mouseMove = e => {
    this.position = {
      x: e.clientX,
      y: e.clientY,
    };
  };

  public getWorldPosition = () => ({
    x: this.game.viewport.corner.x + (this.game.mouse.position.x / this.container.innerWidth) * this.game.viewport.worldScreenWidth,
    y: this.game.viewport.corner.y + (this.game.mouse.position.y / this.container.innerHeight) * this.game.viewport.worldScreenHeight,
  });
}
