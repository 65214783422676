import * as firebase from 'firebase';
import { Body, World } from 'matter-js';
import { v4 } from 'uuid';

import Game from './game';
import Checkpoint from './game/entities/checkpoint';
import Ghost from './game/entities/ghost';
import Platform from './game/entities/platform';
import Saw from './game/entities/saw';
import Spawner from './game/entities/spawner';
import Spike from './game/entities/spike';
import { level0, level1, level2, level3, level4, level5, level6, level7 } from './game/levels';

// import { State } from './index';

const config = {
  apiKey: 'AIzaSyAuaranqgr3lV5ns74WNrDCnu2voiUD5G8',
  authDomain: 'reutemeteut-fc727.firebaseapp.com',
  databaseURL: 'https://reutemeteut-fc727.firebaseio.com',
  projectId: 'reutemeteut-fc727',
  storageBucket: 'reutemeteut-fc727.appspot.com',
  messagingSenderId: '405153994419',
  appId: '1:405153994419:web:78c88653b7b8ae292442c6',
};

// (Matter as any).Detector.canCollide = (filterA, filterB) => {
//   // easy collision filtering monkey patch
//   return (
//     ((filterB.collidesWidth && filterB.collidesWidth.includes(filterA.group)) || filterB.group == 0) && ((filterA.collidesWidth && filterA.collidesWidth.includes(filterB.group)) || filterA.group == 0)
//   );
// };

export default class Manager {
  public static state = {};

  public game: Game;

  public container: HTMLDivElement;

  public canvas: HTMLCanvasElement;

  public debug = false;

  public toggleFade: any;
  public toggleMenu: any;
  public toggleDebug: any;

  public levels: any[] = [];
  public levelIndex: number = 0;

  public profile: { username: string; uuid: string; lastLevelIndex; user: firebase.User } = { username: 'anon', uuid: '', lastLevelIndex: 0, user: null };

  public nextLevelTimeout;

  public database;
  public auth;

  public lobby: firebase.database.Reference;

  public online: boolean = true;

  // tslint:disable-next-line: max-line-length
  constructor(
    opts: { state: any; container: HTMLDivElement; canvas: HTMLCanvasElement; debug: boolean; toggleFade: any; toggleMenu: any; toggleDebug: any } = {
      state: undefined,
      container: null,
      canvas: null,
      debug: false,
      toggleFade: null,
      toggleMenu: null,
      toggleDebug: null,
    },
  ) {
    this.canvas = opts.canvas;
    this.container = opts.container;
    this.debug = opts.debug;
    this.toggleFade = opts.toggleFade;
    this.toggleMenu = opts.toggleMenu;
    this.toggleDebug = opts.toggleDebug;

    if (opts.state) {
      Manager.state = opts.state;
    }

    (window as any).stop = () => this.game.stop();
    (window as any).start = () => this.game.start();

    (window as any).toggleDebug = () => {
      // problem comes from the singleton render, world, ....
      // camera bounds do not get updated unless we pause => play
      this.game.stop();
      this.toggleDebug();
      this.container.innerHTML = null;
      this.debug = !this.debug;

      this.game = new Game({ debug: this.debug, container: this.container, manager: this });
      this.loadLevel(this.levelIndex);

      this.play();
    };
    (window as any).saveLevel = this.saveLevel;
    (window as any).loadLevel = this.loadLevel;

    window.requestAnimationFrame = (() =>
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      (window as any).mozRequestAnimationFrame ||
      (window as any).window.msRequestAnimationFrame ||
      (window as any).window.oRequestAnimationFrame ||
      (f => window.setTimeout(f, 1e3 / 60)))();

    this.listeners();
    this.init();
  }

  public firebase = async () => {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }

    this.database = firebase.database;
    this.auth = firebase.auth;
    try {
      await firebase.auth().signInAnonymously();
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
    }

    const user: firebase.User = await new Promise(res => firebase.auth().onAuthStateChanged((user: firebase.User) => res(user)));
    if (user) {
      const isAnonymous = user.isAnonymous;
      const uid = user.uid;
      // console.log(isAnonymous, uid);

      // for initial load of the application after firebase has been loaded
      this.setupLobby();
    }
    // or https://howtofirebase.com/firebase-authentication-for-web-d58aad62cf6d
    // https://firebase.google.com/docs/auth/web/anonymous-auth
    // link account to anonymous
  };

  public setupLobby = async () => {
    if (firebase.auth().currentUser) {
      if (this.lobby) {
        this.lobby.child(firebase.auth().currentUser.uid).remove();
        this.lobby.off();
      }
      // firebase
      //   .database()
      //   .ref('players/' + firebase.auth().currentUser.uid)
      //   .set({

      //   });

      this.lobby = firebase.database().ref(`levels/${this.levelIndex}`);
      this.lobby.child(firebase.auth().currentUser.uid).set({
        username: this.profile.username,
        level: this.levelIndex,
        body: null,
      });
      this.lobby.on('child_added', data => {
        const key = data.key;
        const player = data.val();
        if (data.key !== firebase.auth().currentUser.uid) {
          const spawner: Spawner = Object.values(this.game.entities).find(entity => entity instanceof Spawner) as Spawner;
          if (spawner) {
            const [x, y] = [spawner.x, spawner.y];
            const ghost = new Ghost({ game: this.game, id: key, width: 25, username: player.username, x, y: y - 25 });
            this.game.addEntity(ghost);
          }
        }
      });

      this.lobby.on('child_changed', data => {
        const player = data.val();
        // console.log(data.key, firebase.auth().currentUser.uid);
        // console.log('updated', data.key, player);
        if (data.key !== firebase.auth().currentUser.uid) {
          // console.log(Number(player.body.position.x), Number(player.body.position.y));
          // debugger;
          // TODO: get key value
          // const entity = Object.values(this.game.entities).find(entity => entity.id === data.key);
          const entity = this.game.entities[data.key];
          if (entity && player.body && player.body.position) {
            // console.log({ ...player.body.position });
            Body.setPosition(entity.body, { ...player.body.position });
            // debugger;
            // entity.body.position.x = player.body.position.x;
            // entity.body.position.y = player.body.position.y;
          }
        }
        // console.log('sup xD', data.key, data.val().text, data.val().author);
      });

      this.lobby.on('child_removed', data => {
        const player = this.game.entities[data.key];
        // console.log('removed', data.key, player);
        if (player) {
          World.remove(this.game.engine.world, player.body);
          this.game.viewport.removeChild(player.sprite);
          delete this.game.entities[player.id];
        }
        // Object.values(this.game.entities).splice(this.game.entities.indexOf(this.game.entities.find(entity => entity.id === data.key)), 1);
        // console.log('sup xD', data.key, data.val().text, data.val().author);
      });
    }
  };

  listeners = () => {
    window.addEventListener('keydown', e => {
      switch (e.which) {
        case 33:
          this.debug && this.previousLevel();
          break;
        case 34:
          this.debug && this.nextLevel();
          break;
        case 220:
          if (this.debug) {
            console.log('level added');
            this.levels.push([]);
          }
      }
    });
  };

  play = () => {
    this.loadLevel(this.levelIndex);
    this.game.start();
    this.container.focus();
  };

  finish = () => {
    if (this.profile.lastLevelIndex < this.levelIndex) {
      this.profile.lastLevelIndex = this.levelIndex;

      localStorage.setItem('lastLevelIndex', `${this.levelIndex}`);
    }

    // console.log(this.levelIndex, this.levels.length);
    if (this.levelIndex === this.levels.length - 1) {
      // TOGGLING MENU
      this.toggleMenu();
      this.levelIndex = 0;
      setTimeout(() => alert('Thanks for playing! Please let me know what you thought about this small demo!'), 1000);
    } else {
      this.fade();
      // if (this.nextLevelTimeout === 0) {
      this.nextLevelTimeout = setTimeout(() => {
        this.nextLevel();
        this.fade();
      }, 1000);
    }
    // }
  };

  // TODO SET PROFILE

  init = () => {
    if (!localStorage.getItem('session')) {
      const uuid = v4();
      this.profile.uuid = uuid;
      localStorage.setItem('session', uuid);
    } else {
      this.profile.uuid = localStorage.getItem('session');
    }

    if (!localStorage.getItem('username')) {
      const username = prompt(`Would you like to set a username? Highscores will be added later!`);
      if (username) {
        this.profile.username = username;
        localStorage.setItem('username', username);
      }
    } else {
      this.profile.username = localStorage.getItem('username');
    }

    if (localStorage.getItem('lastLevelIndex')) {
      this.profile.lastLevelIndex = Number(localStorage.getItem('lastLevelIndex'));
      this.levelIndex = this.profile.lastLevelIndex;
    }

    this.levels = [level0, level1, level7, level2, level4, level3, level6, level5];
    this.game = new Game({ debug: this.debug, container: this.container, manager: this });

    if (this.online) {
      this.firebase();
    } else {
      console.log('------OFFLINE MODE--------');
    }
  };

  // public toggleDebug = () => {
  //   // this.container.innerHTML = null;
  //   // this.debug = !this.debug;
  //   // this.game = new Game({ debug: this.debug, container: this.container, manager: this });
  //   // this.loadLevel(this.levelIndex);
  // };

  public fade = () => {
    this.toggleFade();
  };

  public previousLevel() {
    if (this.levels[this.levelIndex - 1]) {
      this.loadLevel(this.levelIndex - 1);
    }
  }

  public nextLevel() {
    if (this.levels[this.levelIndex + 1]) {
      this.loadLevel(this.levelIndex + 1);
      // this.game.reset();
    }
    //  else {
    //   // this.toggleMenu();

    // }
  }

  public reset = () => {
    // clearInterval(this.nextLevelTimeout);
    // this.nextLevelTimeout = 0;
    this.game.reset();
  };

  public loadLevel = (levelIndex?) => {
    this.game.clear();

    if (!this.levels || !this.levels[levelIndex]) {
      throw new Error('Level not found');
      // console.log('Level not found!');
    }

    // otherwise its false
    if (levelIndex !== undefined) {
      this.levelIndex = levelIndex;
    }

    if (this.levels && this.levels[this.levelIndex] && this.levels[this.levelIndex].length > 0) {
      for (const schema of this.levels[this.levelIndex]) {
        let entity;
        switch (schema.type) {
          case 'Platform':
            entity = new Platform({ game: this.game, ...schema, options: { ...schema, moveOverX: schema.moveOverX, moveOverY: schema.moveOverY } });
            break;
          case 'Saw':
            entity = new Saw({ game: this.game, ...schema, options: { ...schema, radius: schema.radius ? schema.radius : (schema.width * 2) / 4 } });
            break;
          case 'Spawner':
            entity = new Spawner({ game: this.game, ...schema });
            break;
          case 'Checkpoint':
            // entity = new Checkpoint(schema.x, schema.y);
            entity = new Checkpoint({ game: this.game, ...schema });
            break;
          case 'Spike':
            entity = new Spike({ game: this.game, ...schema, options: { ...schema } });
            break;
        }
        if (entity) {
          this.game.addEntity(entity);
          // this.game.entities.push(entity);
        }
      }

      this.reset();
      this.setupLobby();
    }
  };

  // print and save to localStorage
  public saveLevel = (levelIndex: number) => {
    const entities = Object.values(this.game.entities).map(entity => {
      const {
        body: {
          angle,
          position: { x, y },
        },
      } = entity;
      Body.setAngle(entity.body, 0);
      const width = entity.body.bounds.max.x - entity.body.bounds.min.x;
      const height = entity.body.bounds.max.y - entity.body.bounds.min.y;
      Body.setAngle(entity.body, angle);
      return {
        x,
        y,
        width,
        height,
        angle,
        type: entity.constructor.name,
        ...(entity instanceof Platform ? { moveOverX: entity.moveOverX, moveOverY: entity.moveOverY } : {}),
        ...(entity instanceof Saw ? { options: { radius: (entity as any).radius } } : {}),
      };
    });
    this.levels = JSON.parse(localStorage.getItem('levels'));
    this.levels[levelIndex ? levelIndex : this.levelIndex] = entities;
    localStorage.setItem('levels', JSON.stringify(this.levels));
    console.log(JSON.stringify(this.levels[this.levelIndex]));
  };
}
