import { Bodies, Body, Engine, Events, Pairs, Query, Render, Runner, Vector, World } from 'matter-js';
import { Viewport } from 'pixi-viewport';
import { Application, Loader, settings, SCALE_MODES } from 'pixi.js';
import * as notify from 'pling';
import Keyboard from 'pixi.js-keyboard';

import Manager from '../manager';
import Checkpoint from './entities/checkpoint';
import Platform from './entities/platform';
import Player from './entities/player';
import Saw from './entities/saw';
import Spawner from './entities/spawner';
import Spike from './entities/spike';
import Mouse from './mouse';
import Test from './entities/test';
import Entity from './entities/entity';

// export let engine: Engine;
// export let render: Render;
// export let runner: Runner;

export default class Game {
  public width: number;
  public height: number;

  public container: HTMLDivElement;
  public context: CanvasRenderingContext2D;

  public engine: Engine;
  public render: Application;
  public matterRender: Render;
  public loader: Loader;
  public runner: Loader;

  public zoomScale: number = 1;
  public bodies: Bodies;

  public levelFinished: boolean;

  // public player: Matter.Body;

  public mouse: Mouse;

  public debug: boolean;

  public keys: boolean[];
  public released: number[];

  public running: boolean = true;

  public manager: Manager;

  public player: Player;
  public viewport: Viewport;

  public bodyA: Body;
  public bodyB: Body;
  public bodyC: Body;
  public bodyD: Body;

  // this should be key value
  public entities: { [key: string]: Entity };

  public selected: null | Body;
  public initialSelected: null | Body;

  public resetTimeout;

  public loop;

  public playerCollidingPairs: Pairs[] = [];

  // fps should be locked at:
  public fps = 60;
  // init of now to base value
  public now = Date.now();
  // setting then to base value
  public then = this.now;
  // what I want time in between frames to be
  public interval = 1000 / this.fps;
  // init of delta
  public delta = 0;
  public correction = 0;

  public last_run = 0;
  public last_delta = 0;

  public toggleFollow: boolean = false;

  constructor(opts: { debug?: boolean; container?: HTMLDivElement; manager: Manager } = { container: undefined, manager: undefined, debug: false }) {
    this.height = opts.container.clientHeight;
    this.width = opts.container.clientWidth;

    this.debug = opts.debug;

    this.container = opts.container;
    this.manager = opts.manager;

    this.keys = [];
    this.released = Array(500).fill(Date.now());
    this.entities = {};

    this.init();
  }

  public init() {
    this.engine = Engine.create({});

    this.viewport = new Viewport({
      screenWidth: this.width,
      screenHeight: this.height,
      worldWidth: this.width,
      worldHeight: this.height,
      // screenWidth: window.innerWidth,
      // screenHeight: window.innerHeight,
      // worldWidth: window.innerWidth,
      // worldHeight: window.innerHeight,
    });

    this.matterRender = (Render as any).create({
      element: this.container,
      engine: this.engine,
      options: { width: this.viewport.screenWidth, height: this.viewport.screenHeight, showCollisions: true },
    });

    const canvas = document.createElement('canvas');
    this.container.appendChild(canvas);

    this.render = new Application({
      // transparent: true,
      view: canvas,
      width: this.width,
      height: this.height,
      antialias: true,
      backgroundColor: 0xcce6f4,
    });

    this.render.stop();

    this.loader = new Loader();
    // debugger;

    // we need to apppend this to body
    this.render.stage.addChild(this.viewport);

    this.viewport
      .drag()
      .pinch()
      .wheel()
      .decelerate();

    this.mouse = new Mouse(this);
    // this.mouse.pixelRatio = 1;
    // this.runner = (Runner as any).create({});

    // Events.on(this.render, 'afterRender', () => {
    //   // if (!this.debug) return;
    //   // this.render.context.font = '20px Arial';
    //   // this.render.context.fillStyle = 'white';
    //   // this.render.context.fillText(`Reutemeteut ${this.running ? 'Playing' : 'Paused'} - Level ${this.manager.levelIndex}`, this.width - 400, 20);
    // });

    // Events.on(this.engine, 'beforeUpdate', () => {
    //   this.update();
    // });

    // Events.on(this.render, 'collisionStart', event => {
    //   event.pairs.forEach(pair => {
    //     const { bodyA, bodyB } = pair;
    //       const obstacle = bodyB === this.player ? bodyA : bodyB;
    //       this.playerCollidingPairs.push(pair);
    //       const obstacles = this.entities.filter(entity => entity instanceof Saw || entity instanceof Spike).map(entity => entity.body);
    //       // weird thing because it only collides with the individual part (hence we have to check parent)
    //       if (obstacles.includes(obstacle) || obstacles.includes(obstacle.parent)) {
    //         if (process.env.NODE_ENV === 'production')
    //           notify({
    //             key: '6e5279b242a9b9670e5a231dc96410b93a7b527d4ce513b168b9f518e7bcf9fb',
    //             title: `Dead: Level ${this.manager.levelIndex}`,
    //             description: JSON.stringify({ timestamp: this.engine.timing.timestamp / 1000, username: this.manager.profile.username, uuid: this.manager.profile.uuid }),
    //           });
    //         this.die();
    //       }
    //     }
    //   });
    // });

    // Events.on(this.engine, 'collisionEnd', event => {
    //   event.pairs.forEach(pair => {
    //     const { bodyA, bodyB } = pair;
    //     if (bodyB === this.player.body || bodyA === this.player.body) {
    //       const floor = bodyB === this.player.body ? bodyA : bodyB;

    //       this.playerCollidingPairs.splice(this.playerCollidingPairs.indexOf(pair), 1);
    //     }
    //   });
    // });

    // Events.on(this.engine, 'collisionActive', event => {
    //   event.pairs.forEach(pair => {
    //     const { bodyA, bodyB } = pair;
    //
    //   });
    // });

    // Events.on(this.engine, 'afterTick', event => {});
    // Events.on(this.engine, 'beforeTick', event => {
    //   this.entities.forEach(entity => entity.update && entity.update(event));
    //   // setting max velocity
    //   // if (this.player) {
    //   //   const camera = {
    //   //     x: (this.render.bounds.max.x + this.render.bounds.min.x) / 2,
    //   //     y: (this.render.bounds.max.y + this.render.bounds.min.y) / 2,
    //   //   };

    //   //   (Render as any).lookAt(
    //   //     this.render,
    //   //     {
    //   //       x: camera.x + (this.player.body.position.x - camera.x) * 0.01 * this.runner.delta,
    //   //       y: camera.y + (this.player.body.position.y - camera.y) * 0.01 * this.runner.delta,
    //   //     },
    //   //     {
    //   //       x: this.render.options.width / this.zoomScale,
    //   //       y: this.render.options.height / this.zoomScale,
    //   //     },
    //   //   );

    //   const force = 1;

    // keep the mouse in sync with rendering
    // (this.render as any).mouse = this.mouse;
    // this.runner.isFixed = true;

    // this.render.run(this.render);
    // this.update();

    // making sure keydown event listener works by setting tabindex
    this.container.focus();
    this.container.setAttribute('tabindex', '1');

    window.onkeydown = e => {
      // disable zooming
      if (e.ctrlKey == true && (e.which == '61' || e.which == '107' || e.which == '173' || e.which == '109' || e.which == '187' || e.which == '189')) {
        e.preventDefault();
        // 107 Num Key  +
        //109 Num Key  -
        //173 Min Key  hyphen/underscor Hey
        // 61 Plus key  +/=
      }

      this.keyDown(e.keyCode);
    };

    window.onkeyup = e => {
      this.keyUp(e.keyCode);
    };

    window.addEventListener('mousemove', e => {
      //   if (!this.selected && this.debug && e.buttons === 1 && !this.running) {
      //     const camera = {
      //       x: (this.render.bounds.max.x + this.render.bounds.min.x) / 2,
      //       y: (this.render.bounds.max.y + this.render.bounds.min.y) / 2,
      //     };

      //     (Render as any).lookAt(
      //       this.render,
      //       {
      //         x: camera.x - e.movementX,
      //         y: camera.y - e.movementY,
      //       },
      //       {
      //         x: this.render.options.width / this.zoomScale,
      //         y: this.render.options.height / this.zoomScale,
      //       },
      //     );
      //   }
      if (this.selected && this.debug && e.buttons === 1) {
        Body.setPosition(this.selected, this.mouse.getWorldPosition());
      }
    });

    // this.render.canvas.onmouseup = e => {};

    this.container.onmouseup = e => {
      if (this.debug && this.selected) {
        if (!this.initialSelected.isStatic) {
          this.selected.isStatic = false;
        }
      }
    };

    this.container.oncontextmenu = e => {
      return false;
    };

    // this.mouse.element.onwheel = e => {
    //   e.preventDefault();

    //   if (!this.debug) return;
    //   if (this.selected) {
    //     // rotated scale doesnt really work well so we rotate it to an angle of 0 radians and scale. revert angle
    //     const { angle: initialAngle } = this.selected;
    //     Body.setAngle(this.selected, 0);
    //     let scaleY = 1;
    //     let scaleX = e.deltaY < 0 ? 1.05 : 0.95;

    //     if (this.keys[16]) {
    //       scaleY = e.deltaY < 0 ? 1.05 : 0.95;
    //       scaleX = 1;
    //     }

    //     if (this.keys[17]) {
    //       scaleX = e.deltaY < 0 ? 1.05 : 0.95;
    //       scaleY = e.deltaY < 0 ? 1.05 : 0.95;
    //     }

    //     const entity = this.entities.find(entity => entity.body === this.selected);
    //     entity.width *= scaleX;
    //     entity.height *= scaleY;
    //     Body.scale(this.selected, scaleX, scaleY);
    //     Body.setAngle(this.selected, initialAngle);
    //   } else {
    //     // this.zoomScale = e.deltaY < 0 ? (this.zoomScale *= 1.05) : this.zoomScale * 0.95;
    //     if (this.player) {
    //       // debugger;
    //       // (Render as any).lookAt(
    //       //   this.render,
    //       //   {
    //       //     x: this.player.body.position.x,
    //       //     y: this.player.body.position.y,
    //       //   },
    //       //   {
    //       //     x: this.matterRender.options.width / this.zoomScale,
    //       //     y: this.matterRender.options.height / this.zoomScale,
    //       //   },
    //       // );
    //     }
    //   }
    // };

    window.addEventListener('mouseup', e => {
      // Body.setStatic(this.selected, false);
      if (this.selected) {
        // not doing this
        // this.selected.isStatic = false;
        this.selected = null;
      }
    });

    window.addEventListener('resize', e => {
      this.resize(e);
    });
  }

  public keyUp(keyCode: number) {
    this.keys[keyCode] = false;
    this.released[keyCode] = Date.now();
  }

  public keyDown(keyCode: number) {
    this.keys[keyCode] = true;
    this.controls(keyCode);
  }

  public die() {
    // create random particles based on user velocity, ...
    const player = this.player;
    World.remove(this.engine.world, this.player.body);

    // BLOOD SPLATTERS
    // for (let i = 0; i < 5; i++) {
    //   const particle = Bodies.circle(this.player.body.position.x, this.player.body.position.y, Math.random() * 18 + 2, {
    //     label: 'Particle',
    //     friction: 0,
    //     frictionAir: 0.01,
    //     frictionStatic: 0.01,
    //     // force: player.force,
    //     inertia: Infinity,
    //     render: {
    //       strokeStyle: 'red',
    //       fillStyle: 'red',
    //     },
    //   });
    //   Body.setVelocity(particle, player.body.velocity);
    //   World.add(this.engine.world, particle);
    // }

    // this.manager.fade();

    // if (this.resetTimeout === 0) {
    // this.resetTimeout = setTimeout(() => {
    // this.manager.fade();
    // }, 1000);
    // }

    // remove player
    // reset
    // maybe set reset to i nterval, clear interval on R key
    // reset pause and clear pause interval
  }

  public get_delta_correction() {
    let delta = 1000 / 60; //default used on first loop only
    let correction = 1.0; //also default for first iterations
    if (this.last_run == 0) {
      //first run -> no delta, no correction
      const this_run = Date.now();
      this.last_run = this_run;
    } else {
      if (this.last_delta == 0) {
        //second run -> first delta but no correction yet
        const this_run = Date.now();
        delta = this_run - this.last_run;
        if (delta > 100) {
          //avoids instabilities after pause (window in background) or with slow cpu
          delta = 100;
        }
        this.last_run = this_run;
        this.last_delta = delta;
      } else {
        //run > 2 => delta + correction
        const this_run = Date.now();
        delta = this_run - this.last_run;
        if (delta > 100) {
          //avoids instabilities after pause (window in background) or with slow cpu
          delta = 100;
        }
        correction = delta / this.last_delta;
        this.last_run = this_run;
        this.last_delta = delta;
      }
    }
    return { delta: delta, correction: correction };
  }

  public update = (time = 0) => {
    Keyboard.update();
    this.loop = requestAnimationFrame(this.update);

    this.now = Date.now();
    // this.delta = this.now - this.then;
    const { delta, correction } = this.get_delta_correction();
    this.delta = delta;
    this.correction = correction;

    Object.values(this.entities).forEach((entity, i) => {
      if (entity.shouldUpdate) {
        entity.update();
      }

      if (this.debug) {
        (Render as any).lookAt(
          this.matterRender,
          {
            x: this.viewport.center.x,
            y: this.viewport.center.y,
            // y: camera.y + (this.player.body.position.y - camera.y) * 0.01 * this.runner.delta,
          },
          {
            x: this.viewport.screenWidth / 2 / this.viewport.scale.x,
            y: this.viewport.screenHeight / 2 / this.viewport.scale.y,
            // y: this.render.options.height / this.zoomScale,
          },
        );
      }
    });

    Engine.update(this.engine, this.delta, this.correction);
    this.render.render();
    // , this.delta, correction
    // Engine.update(this.engine);
    // debugger;
    // if (this.delta > this.interval && this.engine.enabled) {

    this.then = this.now - (this.delta % this.interval);
    // }

    if (this.mouse.down) {
      if (!this.debug) return;
      const foundPhysics = Query.point(this.engine.world.bodies, this.mouse.position);
      if (foundPhysics.length > 0) {
        const body = foundPhysics[0];

        if (!this.selected || this.selected !== body) {
          if (this.selected !== body && this.initialSelected) {
            this.selected.render = { ...this.initialSelected.render };
          }
          this.initialSelected = { isStatic: body.isStatic, render: { ...body.render } } as any;
          this.selected = body;
          this.selected.isStatic = true;
          this.selected.render.fillStyle = 'red';
        }
      } else {
        if (this.selected && this.initialSelected) {
          this.selected.render = { ...this.initialSelected.render };
        }
        this.selected = null;
        this.initialSelected = null;
      }
    }
  };

  public stop() {
    // runner.enabled = false;
    // this.running = false;
    // if (this.player) this.player.isStatic = true;
    // this.render.stop();
    // this.render.ticker.stop();
    if (this.player) this.player.body.isStatic = true;
    this.engine.enabled = false;
    cancelAnimationFrame(this.loop);
    if (this.debug) {
      Render.stop(this.matterRender);
    }
    // Runner.stop(this.engine);
    // debugger;
  }

  public start() {
    Engine.run(this.engine);
    if (this.debug) {
      Render.run(this.matterRender);
    }

    this.engine.enabled = true;
    // this.render.start();

    // this.render.ticker.add(this.update);

    if (this.player) this.player.body.isStatic = false;
    this.update();
  }

  public resize(e) {
    this.width = e.target.innerWidth;
    this.height = e.target.innerHeight;

    this.matterRender.canvas.width = this.width;
    this.matterRender.canvas.height = this.height;
    this.matterRender.options.width = this.width;
    this.matterRender.options.height = this.height;

    this.render.renderer.resize(this.width, this.height);
    this.viewport.resize(this.width, this.height);
  }

  public reset() {
    this.levelFinished = false;
    clearInterval(this.resetTimeout);
    this.resetTimeout = 0;
    const spawner: Spawner = Object.values(this.entities).find(entity => entity instanceof Spawner) as Spawner;

    if (spawner) {
      const [x, y] = [spawner.x, spawner.y];
      this.player = new Player({ game: this, x, y: y - 25, width: 25, height: 25 });
      this.addEntity(this.player);
      //radius: 150
      this.viewport.follow(this.player.sprite, {});
      // this.viewport.)
    }

    // this.addEntity(new Test({ game: this }));
  }

  public addEntity = entity => {
    World.add(this.engine.world, entity.body);
    this.viewport.addChild(entity.sprite);

    this.entities[entity.id] = entity;
  };

  public clear = () => {
    this.entities = {};
    this.player = null;
    World.clear(this.engine.world, false);
    this.viewport.removeChildren();
  };

  public controls(keyCode) {
    switch (keyCode) {
      case 189:
      // if (this.debug && this.selected) {
      //   const platform = this.entities.find(entity => entity.body === this.selected);
      //   platform.moveOverX -= 10;
      // }

      // break;
      case 187:
      // if (this.debug && this.selected) {
      //   const platform = this.entities.find(entity => entity.body === this.selected);
      //   platform.moveOverX += 10;
      // }

      // break;
      case 219:
      // if (this.debug && this.selected) {
      //   const platform = this.entities.find(entity => entity.body === this.selected);
      //   platform.moveOverY -= 10;
      // }

      // break;
      case 221:
      // if (this.debug && this.selected) {
      //   const platform = this.entities.find(entity => entity.body === this.selected);
      //   platform.moveOverY += 10;
      // }

      // break;
      case 186:
      // if (this.debug && this.selected) {
      //   const platform = this.entities.find(entity => entity.body === this.selected);
      //   platform.moveOverX = 0;
      // }

      // break;
      case 222:
      // if (this.debug && this.selected) {
      //   const platform = this.entities.find(entity => entity.body === this.selected);
      //   platform.moveOverY = 0;
      // }

      // break;
      case 80:
        this.engine.enabled ? this.stop() : this.start();
        break;
      case 82:
        this.clear();
        this.manager.loadLevel(this.manager.levelIndex);
        break;

      case 81:
      // if (this.debug && this.selected) {
      //   const angle = -Math.PI / 20;
      //   const entity = this.entities.find(entity => entity.body === this.selected);
      //   entity.angle += angle;
      //   Body.rotate(this.selected, angle);
      // }
      // break;
      case 69:
        // if (this.debug && this.selected) {
        //   const angle = Math.PI / 20;
        //   const entity = this.entities.find(entity => entity.body === this.selected);
        //   entity.angle += angle;
        //   Body.rotate(this.selected, angle);
        // }
        break;
      case 70:
      // console.log(this.selected);
      // if (this.selected) {
      //   const entity = this.entities.find(entity => entity.body === this.selected);
      //   const newEntity = new entity.constructor({
      //     game: this,
      //     ...this.mouse.position,
      //     ...(entity.length ? { length: entity.length } : {}),
      //     ...(entity.width ? { width: entity.width } : {}),
      //     ...(entity.height ? { height: entity.height } : {}),
      //     ...(entity.radius ? { radius: entity.radius } : {}),
      //     ...(entity.angle ? { angle: entity.angle } : {}),
      //   });

      //   this.entities.push(newEntity);
      // } else {
      //   console.log('Nothing selected');
      // }
      // break;
      case 46:
      // if (this.debug) {
      //   World.remove(this.engine.world, this.selected);
      //   this.entities.splice(this.entities.map(entity => entity.body).indexOf(this.selected), 1);
      // }
      // break;
      case 57:
        // this.debug && this.entities.push(new Checkpoint({ ...this.mouse.position }));
        break;
      // g
      case 71:
        this.toggleFollow = !this.toggleFollow;
        if (this.toggleFollow) {
          this.viewport.follow(this.player.sprite);
        } else {
          this.viewport.plugins.remove('follow');
        }
        break;
      case 48:
        if (this.debug) {
          if (Object.values(this.entities).some(entity => entity instanceof Spawner)) {
            throw new Error('THERE IS ALREADY A SPAWNER');
          }

          // this.entities.push(new Spawner({ ...this.mouse.position }));
        }
        break;
      case 49:
        if (this.debug) {
          const wall = new Platform({ game: this, ...this.mouse.getWorldPosition(), width: 500, height: 50 } as any);
          this.entities[wall.id] = wall;
          this.initialSelected = { isStatic: wall.body.isStatic, render: { ...wall.body.render } } as any;
          this.selected = wall.body;
          this.selected.isStatic = true;
          this.selected.render.fillStyle = 'red';
          this.addEntity(wall);
        }
        break;
      // case 50:
      //   this.debug && this.entities.push(new Saw({ game: this, ...this.mouse.position, radius: 60 }));
      //   break;
      // case 51:
      //   this.debug && this.entities.push(new Spike({ game: this, ...this.mouse.position, length: 20 }));
      //   break;
    }
  }
}
